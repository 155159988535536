import React from 'react';
import './Modal.css';

const Modal = ({ 
        isOpen, onClose, setIsEditing, children
      }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    if (setIsEditing) {
      setIsEditing(false);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-close-container">
          <button className="modal-close" onClick={handleClose}>×</button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;