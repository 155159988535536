import React, { useState, useEffect } from 'react';
import guidanceImage from './pic_tatsuro_1.jpg';
import { collection, addDoc, getDocs, updateDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import './DailyPlan.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesomeのインポート
import Modal from './Modal'; // モーダルコンポーネントをインポート

function DailyPlan({ 
  user,
  goal,
  setGoal,
  plan,
  setPlan,
  score,
  setScore,
  see,
  setSee,
}) {
  const [date, setDate] = useState('');
  const [isEditingGoal, setIsEditingGoal] = useState(false); // 目標コンテナ設定編集のフラグ
  const [isEditingPlan, setIsEditingPlan] = useState(false); // 計画コンテナ設定編集のフラグ
  const [isEditingDo, setIsEditingDo] = useState(false); // 実行コンテナ設定編集のフラグ
  const [isEditingSee, setIsEditingSee] = useState(false); // 反省コンテナ設定編集のフラグ
  const [doText, setDoText] = useState('');
  const [name, setName] = useState('');
  const [judgment, setJudgment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // モーダルの表示・非表示を管理
  const [currentEditing, setCurrentEditing] = useState(null); // 現在編集中のフィールド

  // 初回レンダリング時に日付を設定するuseEffect
  useEffect(() => {
    if (!date) {
      const today = new Date().toISOString().split('T')[0];
      console.log(today); // todayをコンソール出力
      setDate(today);
    }
  }, [date]);

  // 日付が変更されたときにデータを取得するuseEffect
  useEffect(() => {
    const fetchData = async () => {
      if (user && date) {
        const userCollectionName = user.uid;
        const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
        const dateSubCollectionName = date.toString();
        const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');
        const docSnap = await getDoc(subDocRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setGoal(data.goal || '');
          setPlan(data.plan || '');
          setDoText(data.do || '');
          setSee(data.check || '');
          setScore(data.score || '');
          setJudgment(data.judgment || '');
          setName(data.name || '');
        } else {
          // データが存在しない場合、各変数をリセット
          setGoal('');
          setPlan('');
          setDoText('');
          setSee('');
          setScore('');
          setJudgment('');
          setName('');
        }
      }
    };

    fetchData();
  }, [date, user, setGoal, setPlan, setDoText, setSee, setScore, setJudgment, setName]);

  const handlePreviousDay = () => {
    const previousDay = new Date(date);
    previousDay.setDate(previousDay.getDate() - 1);
    setDate(previousDay.toISOString().split('T')[0]);
  };

  const handleNextDay = () => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    setDate(nextDay.toISOString().split('T')[0]);
  };

  const handleEditGoal = () => {
    setIsEditingGoal(true);
    setIsModalOpen(true);
    setCurrentEditing('goal');
  };

  const handleEditPlan = () => {
    setIsEditingPlan(true);
    setIsModalOpen(true);
    setCurrentEditing('plan');
  };

  const handleEditDo = () => {
    setIsEditingDo(true);
  };

  const handleEditSee = () => {
    setIsEditingSee(true);
    setIsModalOpen(true);
    setCurrentEditing('see');
  };

  const handleSaveGoal = async () => {
    setIsEditingGoal(false);
    setIsModalOpen(false); // モーダルを非表示
    if (user) {
      const userCollectionName = user.uid;
      const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
      const dateSubCollectionName = date.toString();
      const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

      const data = {
        goal: goal || '',
        plan: plan || '',
        do: doText || '',
        check: see || '',
        score: score || '',
        judgment: judgment || '',
        date: date,
        name: name || ''
      };

      await setDoc(subDocRef, data);
    }
  };

  const handleSavePlan = async () => {
    setIsEditingPlan(false);
    setIsModalOpen(false); // モーダルを非表示
    if (user) {
      const userCollectionName = user.uid;
      const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
      const dateSubCollectionName = date.toString();
      const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

      const data = {
        goal: goal || '',
        plan: plan || '',
        do: doText || '',
        check: see || '',
        score: score || '',
        judgment: judgment || '',
        date: date,
        name: name || ''
      };

      await setDoc(subDocRef, data);
    }
  };

  const handleScoreChange = async (e) => { // 関数をasyncに変更
    const newScore = e.target.value;
    setScore(newScore);
    setIsEditingDo(true); // スコアが変化したときにisEditingDoをfalseに設定
   /* if (newScore !== '') {
      const numScore = parseInt(newScore, 10);
      if (numScore >= 6) {
        setJudgment('合格');
      } else {
        setJudgment('明日こそ！');
      }
    } else {
      setJudgment('');
    }*/
    if (user) {
      const userCollectionName = user.uid;
      const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
      const dateSubCollectionName = date.toString();
      const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

      const data = {
        //goal: goal || '',
        //plan: plan || '',
        //do: doText || '',
        //check: see || '',
        score: score || '',
        //judgment: judgment || '',
       // date: date,
        //name: name || ''
      };

      await setDoc(subDocRef, data); // awaitを使用
    }
  };

  const handleSaveDo = async () => {
    setIsEditingDo(false);
    if (user) {
      const userCollectionName = user.uid;
      const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
      const dateSubCollectionName = date.toString();
      const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

      const data = {
        goal: goal || '',
        plan: plan || '',
        do: doText || '',
        check: see || '',
        score: score || '',
        judgment: judgment || '',
        date: date,
        name: name || ''
      };

      await setDoc(subDocRef, data);
    }
  };

  const handleSaveSee = async () => {
    setIsEditingSee(false);
    setIsModalOpen(false);
    if (user) {
      const userCollectionName = user.uid;
      const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
      const dateSubCollectionName = date.toString();
      const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

      const data = {
        goal: goal || '',
        plan: plan || '',
        do: doText || '',
        check: see || '',
        score: score || '',
        judgment: judgment || '',
        date: date,
        name: name || ''
      };

      await setDoc(subDocRef, data);
    }
  };

  return (
    <div style={{ backgroundColor: '#ffffff' }}>
      {/* ---------------日付コンテナ----------------- */}
      <div className="date-container">
        <i className="fa-solid fa-caret-left" onClick={handlePreviousDay}></i>
        <button onClick={handlePreviousDay}>前日</button>
        <label>表示日</label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <button onClick={handleNextDay}>翌日</button>
        <i className="fa-solid fa-caret-right" onClick={handlePreviousDay}></i>
      </div>

      {/* ---------------Goal-Planコンテナ----------------- */}
      {/* ---------------Goalフィールド----------------- */}
      <div className="display-container">
        <h1>Plan</h1>
        <div className="display-inner-container">
          <h2>目標を入力しよう</h2>
          {goal === '' ? (
            <textarea
              onClick={handleEditGoal}
              className="input-text"
              rows={1} // 一行に設定
              placeholder="目標を入力しよう！" // プレースホルダーとして促し文を表示
            />
          ) : (
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {goal}
            </p>
          )}
          <div className="button-container">
            {isEditingGoal ? (
              <button onClick={handleSaveGoal}>
                編集
              </button>
            ) : (
              <button onClick={handleEditGoal}>
                入力
              </button>
            )}
          </div>
        </div>
    

      {/* ------planフィールド----------------- */}
      <h2>達成への具体的行動を計画しよう</h2>
      <div className="display-inner-container">
        {plan === '' ? (
          <textarea
            onClick={handleEditPlan}
            className="input-text"
            rows={1} // 一行に設定
            placeholder="目標達成への実行計画を入力しよう！" // プレースホルダーとして促し文を表示
          />
        ) : (
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {plan}
          </p>
        )}
        <div className="button-container">
          {isEditingGoal ? (
            <button onClick={handleSavePlan}>
              編集
            </button>
          ) : (
            <button onClick={handleEditPlan}>
              入力
            </button>
          )}
        </div>
      </div>
  </div>
      {/* ------Doコンテナ----------------- */}
      <div className="display-container" style={{ borderRadius: '15px' 
         , backgroundColor: '#d43e2a'
      }}>
        <h1>Do</h1>
        <div className="display-inner-container" >
          <h2>今、私は、</h2> {plan}
          <h2>を実施しています。</h2>
        </div>
      </div>

{/*
        <div className="guidance-container">
          <div className="guidance-image">
            <img src={guidanceImage} alt="Guidance" />
          </div>
          <div className="guidance-text speech-bubble">
            <div className="speech-bubble-content">
              <p>
                今日の結果から計画と実行の内容を見直してみます<br />
                なぜ「できた」 or 「できなかった」のかを考察して<br />
                そして、明日は、どうするかを検討してみましょう。
              </p>
            </div>
          </div>
        </div> 
*/}

      {/* ------Seeコンテナ----------------- */}
      <div className="display-container">
        <h1>See</h1>
        <div className="display-inner-container">
          <div className="score-container">
           <h2 >今日の成果に点数を付けてみましょう</h2>
            <div >
              <input
                type="number"
                value={score}
                onChange={handleScoreChange}
                placeholder="点数"
                min="0"
                max="10"
              />
            </div>
              <p >点</p>    
          </div>

 {/*    
   <button onClick={() => setScore('')} className="reset-button">
              リセット
            </button>
            
          </div>
          <div className="evaluation-column">
            <div className="score-judgment">
              <p>{judgment}</p>
            </div>
          </div>

*/}
<hr/>
       
          {see === '' ? (
            <textarea
              onClick={handleEditSee}
              className="input-text"
              rows={1} // 一行に設定
              placeholder="今日の結果から明日の改善を導こう！" // プレースホルダーとして促し文を表示
            />
          ) : (
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {see}
            </p>
          )}
          <div className="button-container">
            {isEditingSee ? (
              <button onClick={handleSaveSee}>
                編集
              </button>
            ) : (
              <button onClick={handleEditSee}>
                入力
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        setIsEditing={
          currentEditing === 'goal'
            ? setIsEditingGoal
            : currentEditing === 'plan'
            ? setIsEditingPlan
            : setIsEditingSee
        }
      >
        {(() => {
          switch (currentEditing) {
            case 'goal':
              return (
                <>
                  <textarea
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                    className="modal-text"
                    rows={5}
                  />
                  <button onClick={handleSaveGoal}>保存</button>
                </>
              );
            case 'plan':
              return (
                <>
                  <textarea
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                    className="modal-text"
                    rows={5}
                  />
                  <button onClick={handleSavePlan}>保存</button>
                </>
              );
            case 'see':
              return (
                <>
                  <textarea
                    value={see}
                    onChange={(e) => setSee(e.target.value)}
                    className="modal-text"
                    rows={5}
                  />
                  <button onClick={handleSaveSee}>保存</button>
                </>
              );
            default:
              return null;
          }
        })()}
      </Modal>
    </div>
  );
}

export default DailyPlan;
