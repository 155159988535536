import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDXAioRW4bdmYQAk551H5cp1_V1wuFEjPs",
    authDomain: "plandosee-project-01.firebaseapp.com",
    projectId: "plandosee-project-01",
    storageBucket: "plandosee-project-01.appspot.com",
    messagingSenderId: "863092520546",
    appId: "1:863092520546:web:ca55f0843aa0b1698e833b",
    measurementId: "G-WVMCM7V3QV"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();



export { db, auth, provider };