import './App.css';
import React, { useState, useEffect } from 'react';
import guidanceImage from './pic_tatsuro_1.jpg';
import { db, auth, provider } from './firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import { signInWithPopup, signOut } from 'firebase/auth';
import useMediaQuery from './hooks/useMediaQuery';

import DailyPlan from './DailyPlan';
import WeeklyPlan from './WeeklyPlan';
import MonthlyPlan from './MonthlyPlan';

import '@fortawesome/fontawesome-free/css/all.min.css';//font Awsomeのインポート

function App() {
  const [goal, setGoal] = useState('');
  const [plan, setPlan] = useState('');
  const [doText, setDoText] = useState('');
  const [see, setSee] = useState('');
  const [score, setScore] = useState('');
  const [judgment, setJudgment] = useState('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [name, setName] = useState('');
  const [user, setUser] = useState({
    uid: 'dummyUserId',
    displayName: 'テストユーザー'
  });
  {/*
  const [user, setUser] = useState('');
  */}

  const [isEditingGoal, setIsEditingGoal] = useState(false);
  const [isEditingPlan, setIsEditingPlan] = useState(false);
  //const [isEditingDo, setIsEditingDo] = useState(false);
  const [isEditingSee, setIsEditingSee] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // Added

  const isMobile = useMediaQuery('(max-width: 768px)'); // 768px以下をモバイルとする
  const [forceMobile, setForceMobile] = useState(false); // テスト用のモバイル表示切り替え
  const [isMenuOpen, setIsMenuOpen] = useState(false); // モバイルメニューの開閉状態

  const [menuOpen, setMenuOpen] = useState(false); // メニューの開閉状態
  const [selectedContent, setSelectedContent] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false); // データがダウンロードされたかのフラグ

  useEffect(() => {
    if (user && date) {
      const fetchData = async () => {
        const userCollectionName = user.uid;
        const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
        const dateSubCollectionName = date.toString();
        const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');
        const subDoc = await getDoc(subDocRef);
        if (subDoc.exists()) {
          const data = subDoc.data();
          setGoal(data.goal);
          setPlan(data.plan);
          setDoText(data.do);
          setSee(data.check);
          setScore(data.score ? parseInt(data.score, 10) : ''); // score added
          setJudgment(data.judgment); // judgment added
        } else {
          // データが存在しない場合、新規にデータを作成
          setGoal('');
          setPlan('');
          setDoText('');
          setSee('');
          setScore('');
          setJudgment('');
          await setDoc(subDocRef, {
            goal: '',
            plan: '',
            do: '',
            check: '',
            score: '',
            judgment: '',
            date: date,
            name: ''
          });
        }

       setIsDataLoaded(true); // データがロードされた後にフラグをtrueに設定  
      };
      fetchData();
    }
  }, [date, user]);

  const handleEdit = (setEditingFunc) => {
    setEditingFunc(true);
  };


  const handleSave = async (setEditingFunc) => {
    setEditingFunc(false);
    if (user) {
      const userCollectionName = user.uid;
      const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
      const dateSubCollectionName = date.toString();
      const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

      const data = {
        goal: goal || '',
        plan: plan || '',
        do: doText || '',
        check: see || '',
        score: score || '',
        judgment: judgment || '',
        date: date,
        name: name || ''
      };

      await setDoc(subDocRef, data);
    }
  };

  /*
  const handleScoreChange = (e) => {
    const newScore = e.target.value;
    setScore(newScore);
    if (newScore !== '') {
      const numScore = parseInt(newScore, 10);
      if (numScore >= 60) {
        setJudgment('合格');
      } else {
        setJudgment('明日こそ！');
      }
    } else {
      setJudgment('');
    }
  };
* */

  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setSelectedContent('dailyplan'); // 初期画面の設定としてDailyPlanを表示
      console.log("provider情報:", provider);
      console.log("サインイン成功:", result);
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setSelectedContent(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleButtonClick = async () => {
    if (isEditing && user) {
      try {
        const userCollectionName = user.uid;
        const dailyDataDocRef = doc(db, userCollectionName, 'daily-Data');
        const dateSubCollectionName = date.toString();
        const subDocRef = doc(dailyDataDocRef, dateSubCollectionName, 'contents');

        const data = {
          goal: goal || '',
          plan: plan || '',
          do: doText || '',
          check: see || '',
          score: score || '',
          judgment: judgment || '',
          date: date,
          name: name || ''
        };

        await setDoc(subDocRef, data);
        setIsEditing(false);
      } catch (error) {
        console.error("Error saving data: ", error);
      }
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  
  const renderContent = () => {
    switch (selectedContent) {
      case 'dailyplan':
        return <DailyPlan 
                  user={user} 
                  goal={goal} 
                  setGoal={setGoal} 
                  isEditingGoal={isEditingGoal} 
                  handleSave={handleSave} 
                  handleEdit={handleEdit} 
                  plan={plan} 
                  setPlan={setPlan} 
                  isEditingPlan={isEditingPlan} 
                  score={score} 
                  setScore={setScore} 
                  //handleScoreChange={handleScoreChange} 
                  judgment={judgment} 
                  //isEditingDo={isEditingDo} 
                  //setIsEditingDo={setIsEditingDo} 
                  see={see} 
                  setSee={setSee} 
                  isEditingSee={isEditingSee} 
                  setIsEditingSee={setIsEditingSee} 
                />;
      case 'weeklyplan':
        return <WeeklyPlan />;
      case 'monthlyplan':
        return <MonthlyPlan />;
      default:
        return <p>ここにメインコンテンツが表示されます。</p>;
    }
  };

  return (
    <div className="app-container">

<header style={{ display: 'flex', justifyContent: 'center' }} className="app-header">
  {user ? (
    <div style={{ display: 'flex', flexDirection: 'row', backgroundColor:'#d3d5e4',
     alignItems: 'center', maxWidth: '1000px', width: '100%' }}>
      <div style={{ flexBasis: '25%', display: 'flex', justifyContent: 'flex-start' }}>
        <button className="menu-button" onClick={toggleMenu}>
          {menuOpen ? '✖' : '☰'}
        </button>
        <p style={{fontWeight: 'normal', padding: '3px 0px 3px 10px'}} >マイページ</p>
      </div>

      <div style={{ 
        display: 'flex', flexDirection: 'column', alignItems: 'center', 
        padding:0,
        flexBasis: '50%',  }}>
        <h1>今から始める Plan・Do・See</h1>
        <p style={{ margin: 0 }}>こんにちは{user.displayName}さん</p>
      </div>

      <div style={{ flexBasis: '25%', display: 'flex', justifyContent: 'flex-end'  }}>
        <button className="signout-button" onClick={handleSignOut}>サインアウト</button>
      </div>
    </div>
  ) : (
    <div >
         <h1>今から始めるPlan, Do, See</h1>
    </div>
  )}
</header>

      {user ? (
        <div className={`App-body ${menuOpen ? 'menu-open' : ''}`}>
          {/* 左側のサブコンテナの内容（メニュー） */}
          <nav  className="App-menu">
          <button className="menu-button" onClick={toggleMenu}>閉じる</button>
            <ul >
              <i className="fa-solid fa-house" style={{ color:'Firebrick' }}></i> 今から始める
              <li onClick={() => setSelectedContent('dailyplan')}>
              <i className="fa-solid fa-file-pen" ></i>
                                今日の計画と反省
              </li>
              <li onClick={() => setSelectedContent('weeklyplan')}>
              <i className="fa-solid fa-chart-line" style={{ color:'Dark Gray' }}></i>  
                                今週の振り返り</li>
              <li onClick={() => setSelectedContent('monthlyplan')}>
              <i className="fa-regular fa-calendar-days" style={{ color:'Dark Gray' }}></i>  
                                 今月の振り返り</li>  
   </ul>
      {/*
            <p>人生を変える</p>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>アファメーション</li>
              <li>行動計画</li>
              <li>評価と改善</li>
  </ul>
*/}
          </nav>
          {/* 右側のサブコンテナの内容 */}
          <main className="App-main">
            {isDataLoaded ? renderContent() : <p>Loading...</p>} {/* データがロードされた後にrenderContentを実行 */}
          </main>
        </div>
      ) : (
        <div className="main-container">
          <p>サインインしてください。</p>
          <button onClick={handleSignIn}>Googleでサインイン</button>
        </div>
      )}

      <footer className="app-footer">
        <p>&copy; 2024 Bizitems</p>
        {/*
        <button className="mobile-toggle-button" onClick={() => setForceMobile(!forceMobile)}>
          {forceMobile ? 'PC表示' : 'モバイル表示'}
        </button>
        */}
      </footer>
    </div>
  );
}

export default App;