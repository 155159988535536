import React from 'react';

function MonthlyPlan() {
  return (
    <div style={{ height: '1000px', backgroundColor: '#fff0f5' }}>
      <h2>サブメニュー3のコンテンツ</h2>
      <p>ここにサブメニュー3の内容が表示されます。</p>
    </div>
  );
}

export default MonthlyPlan;
