import React from 'react';

function WeeklyPlan() {
  return (
    <div style={{ height: '1900px', backgroundColor: '#e6e6fa' }}>
      <h2>サブメニュー2のコンテンツ</h2>
      <p>ここにサブメニュー2の内容が表示されます。</p>
    </div>
  );
}

export default WeeklyPlan;
